<template>
    <div class="all_main_wrap">
        <div class="all_header_box">
            <div class="sider_left">
                <span class="title">
                    <span class="crumbs_item crumbs_last">推送消息 /</span>
                    <router-link class="crumbs_item" tag="a" to="/admin/MessageManage/fixed">
                        系统消息推送 /</router-link>
                    <span class="crumbs_item crumbs_last">
                        {{ operations != 2 ? "新建" : "详情" }}消息</span>
                </span>
            </div>
        </div>

        <div class="all_content_box">
            <div class="Up_Down_inner">
                <div class="all_left_name all_required">标题</div>
                <div>
                    <a-input :disabled="operations == 2" class="all_input all_margin-r" placeholder="请输入标题"
                        v-model="formOBJ.title" />
                </div>
            </div>
            <div class="Up_Down_inner">
                <span class="all_left_name all_required">内容</span>
                <div>
                    <a-textarea :disabled="operations == 2" placeholder="请输入内容" :rows="4" style="resize: none"
                        v-model="formOBJ.content" />
                </div>
            </div>
            <div class="Up_Down_inner">
                <span class="all_left_name all_required">推送场景</span>
                <div>
                    <a-input :disabled="operations == 2" class="all_input all_margin-r" placeholder="请输入推送场景"
                        v-model="formOBJ.scene" />
                </div>
            </div>
            <div class="Up_Down_inner">
                <span class="all_left_name all_required">推送对象和规划</span>
                <div>
                    <a-input :disabled="operations == 2" class="all_input all_margin-r" placeholder="请输入推送对象和规划"
                        v-model="formOBJ.rule" />
                </div>
            </div>
            <div class="Up_Down_inner">
                <span class="all_left_name all_required">跳转类型</span>
                <div class="right-box">
                    <div class="Up_Down_inner">
                        <span class="all_left_name">
                            <a-select :disabled="operations == 2" placeholder="跳转类型" v-model="formOBJ.viewType"
                                @change="handleChange" style="width: 400px;">
                                <a-icon slot="suffixIcon" type="caret-down" />
                                <a-select-option :value="0"> 不跳转 </a-select-option>
                                <a-select-option :value="1"> 考试列表页 </a-select-option>
                                <a-select-option :value="3"> 课程详情页 </a-select-option>
                                <!-- <a-select-option :value="13"> 活动详情页 </a-select-option> -->
                                <a-select-option :value="6"> 学习首页 </a-select-option>
                                <a-select-option :value="10"> 个人证书页 </a-select-option>
                                <a-select-option :value="14"> 直播详情 </a-select-option>
                                <!-- <a-select-option :value="15"> 商品栏目 </a-select-option> -->
                                <a-select-option :value="16"> 资讯详情 </a-select-option>
                                <a-select-option :value="17"> 图书教具 </a-select-option>
                            </a-select>
                        </span>
                    </div>
                </div>
            </div>

            <div class="Up_Down_inner"
                v-if="formOBJ.viewType != 0 && formOBJ.viewType != 1 && formOBJ.viewType != 6 && formOBJ.viewType != 10">
                <span class="all_left_name">跳转对象</span>
                <!--
        - 跳转链接 jumpFlag == 1
        - jumpType 1-外部网页链接 2-资讯文章详情   3-课程详情  6-优惠券弹框资讯列表   7-商品栏目列表
      -->
                <template>

                    <div class="Up_Down_inner" v-if="formOBJ.viewType == 3">
                        <div class="all_left_name all_required">课程</div>
                        <div>
                            <a-select :disabled="operations == 2" show-search optionFilterProp="label"
                                class="input all_margin-r" v-model="formOBJ.view" placeholder="请选择需要跳转的课程"
                                @change="seleteItem">
                                <a-icon slot="suffixIcon" type="caret-down" />
                                <a-select-option :value="item.courseId" v-for="(item, index) in courseList" :key="index"
                                    :label="item.courseName">
                                    {{ item.courseName }}
                                </a-select-option>
                            </a-select>
                        </div>
                    </div>

                    <div class="Up_Down_inner" v-if="formOBJ.viewType == 16">
                        <div class="all_left_name all_required">资讯文章</div>
                        <div>
                            <a-select :disabled="operations == 2" show-search optionFilterProp="label"
                                class="input all_margin-r" v-model="formOBJ.view" placeholder="请选择需要跳转的资讯文章"
                                @change="seleteItem">
                                <a-icon slot="suffixIcon" type="caret-down" />
                                <a-select-option :value="item.id" v-for="(item, index) in newsList" :key="index"
                                    :label="item.articleTitle">
                                    {{ item.articleTitle }}
                                </a-select-option>
                            </a-select>
                        </div>
                    </div>

                    <div class="Up_Down_inner" v-if="formOBJ.viewType == 14">
                        <div class="all_left_name all_required">直播详情</div>
                        <div>
                            <a-select :disabled="operations == 2" show-search optionFilterProp="label"
                                class="input all_margin-r" v-model="formOBJ.view" placeholder="请选择需要跳转的直播"
                                @change="seleteItem">
                                <a-icon slot="suffixIcon" type="caret-down" />
                                <a-select-option :value="item.id" v-for="(item, index) in liveInfoList" :key="index"
                                    :label="item.title">
                                    {{ item.title }}
                                </a-select-option>
                            </a-select>
                        </div>
                    </div>

                    <!-- <div class="Up_Down_inner" v-if="formOBJ.viewType == 15">
                        <div class="all_left_name all_required">商品栏目</div>
                        <div>
                            <a-cascader :disabled="operations == 2" class="cascader-input" :options="options"
                                placeholder="请选择商品栏目" :fieldNames="{
                                    label: 'title',
                                    value: 'columnCode',
                                    children: 'subset'
                                }" change-on-select v-model="courseJumpLink" @change="changejumpLink" />
                        </div>
                    </div> -->

                    <div class="Up_Down_inner" v-if="formOBJ.viewType == 17">
                        <div class="all_left_name all_required">图书教具</div>
                        <div>
                            <a-select :disabled="operations == 2" show-search optionFilterProp="label"
                                class="input all_margin-r" v-model="formOBJ.view" placeholder="请选择需要跳转的图书教具"
                                @change="seleteItem">
                                <a-icon slot="suffixIcon" type="caret-down" />
                                <a-select-option :value="item.productId" v-for="(item, index) in bookList" :key="index"
                                    :label="item.productName">
                                    {{ item.productName }}
                                </a-select-option>
                            </a-select>
                        </div>
                    </div>
                </template>
            </div>

            <div class="L_R_inner">
                <span class="all_left_name all_required">是否APP推送：</span>
                <div class="right-box">
                    <div class="Up_Down_inner">
                        <span class="all_left_name">
                            <a-radio-group v-model="formOBJ.appmsgFlag" @change="onChange">
                                <div class="box-first">
                                    <div class="right_Div">
                                        <a-radio :value="1" :disabled="operations == 2">是</a-radio>
                                        <!-- 给全部用户发消息时，只有推送，前端不接收消息列表数据 -->
                                        <a-radio :value="0" :disabled="operations == 2 || pushPeopleType == 2">否</a-radio>
                                    </div>
                                    <span style="color: red; font-size: 12px;">“APP推送”是手机顶部消息，发送后无法撤回，请谨慎发送。</span>
                                </div>

                            </a-radio-group>
                        </span>
                    </div>
                </div>
            </div>
            <div class="L_R_inner">
                <span class="all_left_name all_required">推送方式：</span>
                <div class="right-box">
                    <div class="Up_Down_inner">
                        <span class="all_left_name">
                            <a-radio-group v-model="pushType" @change="onChange">
                                <div class="box-first">
                                    <div class="right_Div">
                                        <a-radio :value="1" :disabled="operations == 2">实时推送</a-radio>
                                        <a-radio :value="0" disabled>定时推送</a-radio>
                                    </div>
                                </div>

                            </a-radio-group>
                        </span>
                    </div>
                </div>
            </div>
            <!-- <div class="Up_Down_inner">
                <span class="all_left_name">推送时间</span>
                <div>
                    <a-date-picker :disabled="operations == 2" @change="onChanges" placeholder="请选择推送时间"
                        format="YYYY-MM-DD HH:mm:ss" v-model="formOBJ.pushTime" :disabled-date="disabledDate" :showTime="{
                            initialValue: formOBJ.pushTime
                                ? moment('00:00:00', 'HH:mm:ss')
                                : null,
                        }" />
                </div>
            </div> -->
            <div class="L_R_inner">
                <span class="all_left_name all_required">推送人群：</span>
                <div class="right-box">
                    <div class="Up_Down_inner">
                        <span class="all_left_name">
                            <a-select :disabled="operations == 2" placeholder="请选择推送人群" v-model="pushPeopleType"
                                @change="handlePeopleChange">
                                <a-icon slot="suffixIcon" type="caret-down" />
                                <a-select-option :value="1"> 暂不推送 </a-select-option>
                                <a-select-option :value="2"> 所有用户 </a-select-option>
                                <a-select-option :value="3"> 部分用户 </a-select-option>
                            </a-select>
                        </span>
                    </div>
                </div>
            </div>
            <div class="L_R_inner" v-if="pushPeopleType == 3">
                <span class="all_left_name all_required">目标人群：</span>
                <div class="right-box">
                    <div class="Up_Down_inner">
                        <span class="all_left_name">
                            <a-input :disabled="operations == 2" class="all_input all_margin-r"
                                placeholder="多个用户以英文（逗号）隔开" v-model="formOBJ.phoneList" />
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <div class="button">
            <a-button type="primary" class="btn" @click="pushData()" :loading="submitLoad"
                v-if="operations != 2">确认</a-button>
            <a-button class="all_boder_btn btn" @click="$router.go(-1)">取消</a-button>
        </div>

    </div>
</template>

<script>
const columns = [
    {
        title: "序号",
        align: "center",
        dataIndex: "index",
        scopedSlots: {
            customRender: "index",
        },
    },
    {
        title: "商品信息",
        align: "center",
        dataIndex: "productName",
    },
    {
        title: "商品类型",
        align: "center",
        dataIndex: "productType",
        scopedSlots: { customRender: "productType" },
    },

    {
        title: "原价",
        align: "center",
        dataIndex: "couponPrice",
        scopedSlots: { customRender: "price" },
    },
];
export default {
    // 可用组件的哈希表
    components: {},
    // 接收传值
    props: {},
    // 数据对象
    data() {
        return {
            ImagefileList: [],
            relation: false,
            columns,
            tableData: [],
            total: 0,
            pageNumber: 1, //页码
            pageSize: 10, // 页数
            value: 1,
            pushType: 1, // 1：实时推送  2：定时推送（当前迭代只实现实时推送）
            pushPeopleType: 1, // 1：暂不推送   2：全部用户  3：部分用户   
            formOBJ: {
                title: null, //标题
                content: null, //内容
                scene: null, // 推送场景
                rule: null, // 推送人群和触发条件描述
                // 类型  0.不跳转页面  1.考试列表页  2.**课程考试报名页  3.课程详情页  4.课程观看页  5.直播详情  6.学习首页  7.学习打卡  8.完善密码页  9.身份认真页  10.个人证书页  11.设置页   12.发票列表  13.活动详情页   14.直播（新）    15.商品栏目   16.资讯详情   17.图书教具
                viewType: 0, // 跳转类型,默认不跳转
                view: undefined, // 跳转页面 参数
                appmsgFlag: 0, // 是否APP消息推送  1：是 0：否
                pushTime: null, //	推送时间
                phoneList: null, //	部分发送：用户手机号，以逗号分隔；   暂不发送：null；    全部发送：allUser；
                msgType: 3, // 消息类型  1系统消息 2学习消息 3人工消息
            },
            courseList: [], // 课程列表
            bookList: [], // 图书教具列表
            newsList: [], // 资讯列表
            liveInfoList: [], // 直播列表
            options: [], // 商品栏目树
            courseJumpLink: [], // 商品栏目回显

            // 传值
            id: "",
            operations: "",
            loading: false,
            categoryname: "",
            submitLoad: false,
        };
    },
    // 事件处理器
    methods: {
        range(start, end) {
            const result = [];
            for (let i = start; i < end; i++) {
                result.push(i);
            }
            return result;
        },

        disabledDate(current) {
            // Can not select days before today and today
            return current && current < moment().subtract(1, "days").endOf("day");
        },
        handleChange(value) {
            this.formOBJ.view = undefined
            this.formOBJ.viewType = value;
            // 下拉框默认值
            if (value == 15) {
                this.$set(this, 'courseJumpLink', [])
            }
        },
        handlePeopleChange(value) {
            switch (value) {
                case 1:
                    this.formOBJ.phoneList = null;
                    break;
                case 2:
                    this.formOBJ.phoneList = "allUser";
                    this.formOBJ.appmsgFlag = 1
                    break;
                case 3:
                    this.formOBJ.phoneList = "";
                    break;

                default:
                    break;
            }
        },
        callback(e) { },
        relationCanael() {
            this.relation = false;
        },
        relationFilling() {
            this.relation = true;
        },
        onChange(e) {
            // console.log("radio checked", e.target.value);
        },
        onChanges(date, dateString) {
            // console.log(date, dateString);
            this.formOBJ.pushTime = dateString;
        },
        // 提交/编辑
        pushData() {
            if (!this.formOBJ.title) {
                this.$message.warning("请正确填写标题");
                return;
            }
            if (!this.formOBJ.content) {
                this.$message.warning("请正确填写内容");
                return;
            }
            if (!this.formOBJ.scene) {
                this.$message.warning("请正确填写推送场景");
                return;
            }
            if (!this.formOBJ.rule) {
                this.$message.warning("请正确填写推送对象和规划");
                return;
            }
            console.log("====", this.formOBJ.phoneList);
            
            if (this.pushPeopleType == 3 && this.formOBJ.phoneList == "") {
                this.$message.warning("请正确填写目标人群");
                return;
            }
            // 正则表达式：只包含数字和英文逗号
            const regex = /^[0-9,]*$/;
            if (this.pushPeopleType == 3 && !regex.test(this.formOBJ.phoneList)) {
                this.$message.warning("目标人群只允许输入数字和英文逗号");
                return;
            }
            let _that = this
            if (this.pushPeopleType != 1) {
                // this.$info({
                //     title: '提示信息',
                //     content: h('div', {}, [
                //         h('p', 'some messages...some messages...'),
                //     ]),
                //     onOk() { },
                // });
                this.$confirm({
                    title: '提示信息',
                    content: this.pushPeopleType == 2 ? '该消息将给所有用户发送，请核实确认后点击确定按钮。' : '即将进行，请核实后点击确定按钮。',
                    onOk() {
                        _that.pushAction();
                    },
                    onCancel() { },
                });
            } else {
                this.pushAction();
            }
        },

        changejumpLink(e) {
            this.$set(this.formOBJ, 'view', JSON.stringify(e))
        },

        pushAction() {
            let urls = "/hxclass-management/default/msg/addDefaultMsg";
            let method = "post";

            // 跳转方式没有值时，修改默认传值为0
            if (this.formOBJ.view == undefined) {
                this.formOBJ.view = 0
            }
            if (this.submitLoad) {
                return
            }
            this.submitLoad = true
            this.$ajax({
                url: urls,
                method: method,
                params: this.formOBJ,
            }).then((res) => {
                this.submitLoad = false
                if (res.code == 200 && res.success) {
                    this.$message.success("操作成功");
                    let _that = this;
                    this.$success({
                        title: '操作成功',
                        // JSX support
                        content: (
                            <div>
                                <p>用户消息发送中，点击进入列表查看发送记录。</p>
                            </div>
                        ),
                        onOk() {
                            _that.$router.go(-1);
                        },
                    });
                } else {
                    this.$message.error(res.message);
                }
            });
        },

        // 获取消息详情
        getDetail(e) {
            this.$ajax({
                url: "/hxclass-management/default/msg/manage/details/" + e,
                method: "get",
            }).then((res) => {
                // console.log(res.data);
                if (res.code == 200 && res.success) {
                    for (let key in res.data) {
                        this.formOBJ[key] = res.data[key];
                        let phoneList = res.data["phoneList"];
                        if (phoneList == null) {
                            this.pushPeopleType = 1;
                        } else if (phoneList == "allUser") {
                            this.pushPeopleType = 2;
                        } else {
                            this.pushPeopleType = 3;
                        }
                        if (res.data["view"] == null) {
                            this.formOBJ["view"] = 0;
                        } else {
                            this.formOBJ["view"] = Number(res.data["view"]);
                        }
                        if (this.formOBJ.viewType == 15) {
                            this.$set(this, 'courseJumpLink', JSON.parse(res.data.view))
                        }
                    }
                    // console.log([res.data.productId]);
                }
            });
        },

        // 获取课程列表
        getCourseList() {
            this.$ajax({
                url: "/hxclass-management/course/select",
                method: "get",
            }).then((res) => {
                if (res.code == 200 && res.success) {
                    this.courseList = res.data;
                } else {
                    this.$message.error(res.message);
                }
            });
        },

        // 获取图书教具列表
        getBookList() {
            this.$ajax({
                url: "/hxclass-management/product-archives/getProductBookSelection",
                method: "get",
            }).then((res) => {
                if (res.code == 200 && res.success) {
                    this.bookList = res.data;
                } else {
                    this.$message.error(res.message);
                }
            });
        },

        // 资讯列表
        getData() {
            this.$ajax({
                url: "/hxclass-management/Article/list",
                method: "GET",
                params: {
                    columnId: '',
                    pageNum: 1,
                    pageSize: 9999,
                },
            }).then((res) => {
                this.newsList = res.data.records;
            });
        },

        // 直播列表
        getLiveInfoList() {
            this.$ajax({
                url: "/hxclass-management/liveInfo/getLiveInfoList",
                method: "GET",
                params: {
                    pageNum: 1,
                    pageSize: 9999,
                },
            }).then((res) => {
                this.liveInfoList = res.data.records;
            });
        },

        // 查询列表数据
        getShopList() {
            this.$ajax({
                url: '/hxclass-management/ProductColumn/tree',
                params: {
                    title: this.title
                }
            }).then(res => {
                if (res.code == 200 && res.success) {
                    this.options = res.data
                }
            }).catch(err => { })
        },
        // 选择课程/文章
        seleteItem(e) {
            console.log("======", e)
            // this.formOBJ.view = e;
        },
    },
    // 生命周期-实例创建完成后调用
    created() {
        this.operations = this.$route.query.operation;
        this.id = this.$route.query.id;
        if (this.operations != 1) {
            this.getDetail(this.id);
        }
    },
    // 生命周期-实例挂载后调用
    mounted() {
        this.getCourseList(); // 获取课程列表
        this.getData(); // 获取资讯列表
        // this.getShopList(); // 获取商品栏目列表
        this.getLiveInfoList(); // 直播列表
        this.getBookList(); // 图书教具列表
    },
    // 生命周期-实例销毁离开后调用
    destroyed() { },
    // 计算属性监听
    computed: {},
    // 自定义的侦听器
    watch: {},
};
</script>

<style lang="less"  scoped>
.all_main_wrap {
    a {
        color: #333333;
    }

    .all_content_box {
        .Up_Down_inner {
            display: block;

            textarea.ant-input,
            .all_input {
                width: 525px;
            }

            textarea.ant-input {
                height: 192px;
            }

            .ant-calendar-picker,
            .input {
                width: 397px;
            }
        }

        .L_R_inner {
            .right-box {
                .box-first {
                    width: 471px;

                    .right_Div {
                        display: inline-block;
                        line-height: 37px;

                        .ant-select {
                            width: 166px;
                        }
                    }
                }

                .Up_Down_inner {
                    margin-left: 20px;

                    .all_left_name {
                        display: flex;
                        align-items: center;

                        /deep/ .ant-select {
                            width: 228px;
                        }
                    }
                }
            }
        }
    }

    .button {
        .btn {
            margin: 24px 14px;
        }
    }
}

/deep/.ant-modal-body {
    overflow: hidden;
}

/deep/.ant-modal-title {
    color: #000000;
    text-align: center;
}

/deep/.ant-modal-header {
    border-bottom: none;
}

/deep/.ant-modal-footer {
    text-align: center;
    border-top: none;
}

/deep/ .ant-modal-body {
    padding: 0 24px 0px;
}

/deep/.ant-tabs-ink-bar-animated {
    position: relative;
    top: -10px;
}

.table-template {
    /deep/.ant-table {
        box-shadow: none;
    }
}

.search-box {
    margin-top: 40px;
    display: flex;
    margin-bottom: 10px;

    .search-input {
        width: 62%;
        margin-right: 24px;
        margin-bottom: 6px;
    }
}

.tips {
    margin-top: 5px;
    font-size: 12px;
    color: #ff3838;
}

/deep/.ant-modal-footer {
    margin-top: 16px;
}

/deep/.ant-table-column-title {
    white-space: nowrap;
}
</style>